import React from 'react'
// import Cards from '../cards'
import Customers from './customers'

const BodyComp = () => {
    return (
        <>
            <div className="body d-flex py-3">
                <div className="container-xxl">
                  
                   <Customers/>
                </div>
            </div>

        </>
    )
}

export default BodyComp