import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import CustDetails from "../../components/custdetails"
import Header from "../../components/header"
import Sidebar from "../../components/sidebar"
import Cookies from "js-cookie"
import Loader from "../../components/loader"



const CustInfo = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const cookie = Cookies.get('Authorization')

        if (!cookie) {
            navigate("/")
        }

    })

    const { userid } = useParams()
    const [cust, setCust] = useState()
    const [isloading, setIsloading] = useState(true)

    useEffect(() => {
        const getCustDetails = async () => {
            const config = {
                headers: {
                    'Authorization': `${Cookies.get('Authorization')}`,
                }
            };
            const client = await axios.post(' https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/organization', {
                OrganizationID: userid
            }, config)
            
            setCust(client.data)
            setIsloading(false)
        }

        setIsloading(true)
        getCustDetails()
    }, [userid])

    return (
        <>
            <div id="ebazar-layout" className="theme-blush">
                <Sidebar />
                <div className="main px-lg-4 px-md-4">
                    <Header />
                    {
                        isloading ? <Loader />:<CustDetails cust={cust} />
                    }
                </div>
            </div>
        </>
    )
}

export default CustInfo