import { useNavigate } from 'react-router-dom'
import GatewayComp from '../../components/gatewaycomp/GatewayComp'
import Header from '../../components/header'
import Sidebar from '../../components/sidebar'
import Cookies from 'js-cookie'
import { useEffect } from 'react'

const Gateway = () => {
  const navigate = useNavigate()

  useEffect(() => {
      const cookie = Cookies.get('Authorization')

      if (!cookie) {
          navigate("/")
      }

  })

  return (
    <>
      <div id="ebazar-layout" className="theme-blush"> 
       <Sidebar/> 
       
       <div className="main px-lg-4 px-md-4">
          <Header/>
          <GatewayComp/>
       </div>
      </div>
    </>
  )
}

export default Gateway