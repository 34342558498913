import { useEffect } from 'react'
import Header from '../../components/header'
import MqttComp from '../../components/mqttcomp/MqttComp'
import Sidebar from '../../components/sidebar'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'


const Mqtt = () => {
  const navigate=useNavigate()
  
  useEffect(()=>{
   const cookie= Cookies.get('Authorization')
   
    if(!cookie){
      navigate("/")
    }

  })
  return (
    <>
      <div id="ebazar-layout" className="theme-blush"> 
       <Sidebar/> 
       
       <div className="main px-lg-4 px-md-4">
          <Header/>
          <MqttComp/>
       </div>
      </div>
    </>
  )
}

export default Mqtt