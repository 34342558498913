import './notfound.css'



const NotFound = () => {
    return (
        <>
            <div id='oopss'>
                <div id='error-text'>
                    <img src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg" alt="404" />
                    <span>404 PAGE</span>
                    <p className="p-a">
                        . Oooops! You weren't supposed to see this. </p>
                    <p className="p-b">
                        
                    </p>
                    <a href='/' className="back">...back</a>
                </div>
            </div>


        </>
    )
}

export default NotFound