import './NewGatewayComp.css'

const Card = ({newgateway}) => {
   

    return (
        <div className="card card-margin my-3">
            <div className="card-header no-border">
                <h5 className="card-title">Zodhya</h5>
            </div>
            <div className="card-body pt-0">
                <div className="widget-49">
                    <div className="widget-49-title-wrapper">
                      
                        <div className="widget-49-meeting-info">
                            <span className="widget-49-pro-title">Gateway ID:<strong> {newgateway.gatewayID} </strong></span>
                            <span className="widget-49-pro-title">Username: <strong> {newgateway.username}  </strong></span>
                            <p>successfully registered.</p>
                            
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}


export default Card