import { Link } from "react-router-dom"
import {SiMqtt,SiGooglehome,SiBilibili} from "react-icons/si"
import { FaFileInvoiceDollar } from "react-icons/fa";
import {BiPlusMedical} from "react-icons/bi"
import {BsFillPeopleFill,BsFillSendFill} from 'react-icons/bs'
import {IoMdAnalytics} from 'react-icons/io'
// import {FaGripLines} from "react-icons/fa"

const Sidebar = () => {
    return (
        <>
            <div className="sidebar px-4 py-4 py-md-4 me-0">
                <div className="d-flex flex-column h-100">
                    <a href="/dashboard" className="mb-0 brand-icon">
                        <span className="logo-icon">
                            <i className="bi bi-bag-check-fill fs-4"></i>
                        </span>
                        <span className="logo-text">Zodhya</span>
                    </a>

                    <ul className="menu-list flex-grow-1 mt-3">
                        <li><Link to="/dashboard" className="m-link active"> <SiGooglehome className="icofont-home" /> <span>Home</span></Link></li>
                        <li><Link to="/gateway" className="m-link active"> <SiBilibili className="icofont-home"/> <span>Gateways</span> </Link></li> 
                        <li><Link to="/newregister" className="m-link active"> <BsFillPeopleFill className="icofont-home"/> <span>New register</span> </Link></li>    
                        <li><Link to="/newgateway" className="m-link active"> <BiPlusMedical className="icofont-home"/> <span> Add gateway</span> </Link></li>    
                        {/* <li><Link to="/mqtt" className="m-link active"> <SiMqtt className="icofont-home"/> <span>Mqtt</span> </Link></li>     */}
                        <li><Link to="/ota" className="m-link active"> <BsFillSendFill className="icofont-home"/> <span> OTA</span> </Link></li> 
                        <li><Link to="/report" className="m-link active"><IoMdAnalytics className="icofont-home"/><span> Report</span> </Link></li>    
                        <li><Link to="/invoice" className="m-link active"><FaFileInvoiceDollar className="icofont-home"/><span> Generate Invoice</span> </Link></li>    
                    
                    </ul>

                    {/* <button type="button" className="btn btn-link sidebar-mini-btn text-light">
                        <span className="ms-2"><FaGripLines className="icofont-bubble-right"/></span>
                    </button> */}
                </div>
            </div>
        </>
    )
}

export default Sidebar