import Node from './Node'
import {BiSolidDownload} from "react-icons/bi"
// import xlsx from "json-as-xlsx"

const GatewayDetails = ({ gateway }) => {

    const fireBaseTime = new Date(
        gateway.CreatedAt._seconds * 1000 + gateway.CreatedAt._nanoseconds / 1000000,
    );
    const date = fireBaseTime.toDateString();

    const downloadXlsx=()=>{
        const printableContent = document.getElementById("printable-content");
                const printWindow = window.open("", "", "height=1000,width=1000");
                printWindow.document.write(printableContent.innerHTML);
                printWindow.print();
    }

    return (
        <>
            <div className="container-xxl" id='printable-content'>
                <div className="row align-items-center">
                    <div className="border-0 mb-4">
                        <div className="card-header no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 className="fw-bold mb-0 py-3 pb-2">Gateway Details</h3>
                            <BiSolidDownload onClick={downloadXlsx}  style={{"cursor": "pointer"}}/>

                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="card p-xl-5 p-lg-4 p-0">
                            <div className="card-body">
                                <div className="mb-3 pb-3 border-bottom">
                                    Gateway ID:
                                    <strong>{gateway.gatewayID}</strong>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-sm-12">
                                        <div><strong>Organization ID: {gateway.OrganizationID}</strong></div>
                                        <div>Created at: {date}</div>
                                        <div>Gateway Name: {gateway.name}</div>

                                        <div>IMEI: {gateway.ec200ucnIMEI}</div>
                                        <div>UCN Version: {gateway.ec200ucnversion}</div>
                                        <div>Gateway Version: {gateway.gatewayversion}</div>
                                        <div>Gateway Mac: {gateway.gatewaymac}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="border-0 mb-4">
                        <div className="card-header no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 className="fw-bold mb-0 py-3 pb-2">Node Details</h3>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                    <div className="row g-3 mb-3 row-cols-1 row-cols-md-1 row-cols-lg-2 row-deck">
                        <Node node={gateway.node1} no={"01"} />
                        <Node node={gateway.node2} no={"02"} />
                        <Node node={gateway.node3} no={"03"} />
                        <Node node={gateway.node4} no={"04"} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GatewayDetails