import Header from "../../components/header"
import InvoiceComp from "../../components/invoicecomp"
import Sidebar from "../../components/sidebar"

const Invoice = () => {
  return (
   <>
    <div id="ebazar-layout" className="theme-blush"> 
       <Sidebar/> 
       
       <div className="main px-lg-4 px-md-4">
          <Header/>
           <InvoiceComp/>
       </div>
      </div>
   </>
  )
}

export default Invoice