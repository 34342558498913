import { useEffect, useState } from "react"
import axios from "axios"
import List from "./List"
import Cookies from "js-cookie"
import Loader from "../../loader/Loader"
import {BiSolidDownload} from "react-icons/bi"
import xlsx from "json-as-xlsx"
import './customer.css'

const Customers = () => {
   
    const [allcustomer, setAllcustomer] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {

        const getAllCustomer = async () => {

            const config = {
                headers: {
                    'Authorization': `${Cookies.get('Authorization')}`,
                }
            };

            const allCustomer = await axios.get('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/allorganizations', config)
           
            setAllcustomer(allCustomer.data)
            setIsLoading(false)

        }
        setIsLoading(true)
        getAllCustomer()
    }, [])

  const downloadXlsx=()=>{
    let settings = {
        fileName: "MySpreadsheet", // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      }

    let data=[{
        sheet:"Customers",
        columns: [
            { label: "Email", value: "Email" }, // Top level data
            { label: "Address", value: "Address" }, // Custom format
            { label: "OrganizationID", value: "OrganizationID" },
            { label: "username",value:"username"}
          ],
          content:allcustomer,
    }]
    xlsx(data, settings)
  }
    return (
        <>
            <div className="row g-3 mb-3">
                <div className="col-md-12 col-sm-12">
                    <div className="card">
                        <div
                            className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                            <h6 className="m-0 fw-bold">All Customers</h6>
                            <BiSolidDownload onClick={downloadXlsx}  style={{"cursor": "pointer"}}/>
                        </div>
                       
                        <div className="card-body">
                            {isLoading ?
                                <Loader />
                                :
                                <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Username</th>                                                     
                                                        <th scope="col">Registered At</th>
                                                        <th scope="col">#</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allcustomer.map((data) => {

                                                            return (
                                                                <List key={data.username} cust={data} />
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Customers