import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
// import * as MQTT from "precompiled-mqtt";

import * as MQTT from "mqtt/dist/mqtt.min";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MqttComp = () => {
    const [mqtttopic, setMqtttopic] = useState("")
    const [mqttmessage, setMqttmessage] = useState("")
    const [gateway_id, setGateway_id] = useState("")
    const [gatewayids, setGatewayids] = useState([])

    const [option, setOption] = useState("single")
    // const [client, setClient] = useState()

    const onOptionChange = e => {
        setOption(e.target.value)
    }


    const resetForm = () => {
        setMqttmessage("")
        setMqtttopic("")
        setGateway_id("")
    }

    useEffect(() => {
        const fetchgateway = async () => {
            const config = {
                headers: {
                    'Authorization': `${Cookies.get('Authorization')}`,
                }
            };
            const fetchedgateways = await axios.get('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/allgateway', config)
            let gatewayIds = fetchedgateways.data.map(gateway => gateway.gatewayID)
            setGatewayids(gatewayIds)
        }
        fetchgateway()
    }, [])


    const MqttPublish = (e) => {
        e.preventDefault();
        let opt = {
            clientId: 'iotconsole-649af61a-5bb1-4a4c-a1e0-76a75a9fd1f7',
            clean: true,
            username: "AKIASLNTBIH665RLMI3D",
            password: "HjxWWI/aZRnv+Ih4nNHQ+j9wDUjzoe2FHkItQxuj",
            reconnectPeriod: 0,
            protocolId: 'MQTT',
            protocolVersion: 5,
            port: 443
        };
        
        let client = MQTT.connect("wss://a381jvamqe2nox-ats.iot.ap-south-1.amazonaws.com/mqtt?x-amz-customauthorizer-name=mqttAuthorizer", opt)
      
        const sendSingleDevice = async() => {

            try {
                client.publish(mqtttopic + "/" + gateway_id, JSON.stringify({ Data: `${mqttmessage}` }));

                toast.success(`"${mqttmessage}" Published on topic "${mqtttopic}" .`, {
                    icon: "🚀"
                })
                // client.end()
                resetForm();

            } catch (error) {
                toast.error(error)
            }
        }

        const sendAllDevice = () => {

            try {
                gatewayids.map(deviceid => (client.publish(mqtttopic + "/" + deviceid, JSON.stringify({ Data: `${mqttmessage}` }))))
                toast.success(`"${mqttmessage}" Published on topic "${mqtttopic}" .`, {
                    icon: "🚀"
                })
                resetForm();

            } catch (error) {
                toast.error(error)
            }
        }

        option === "single" ?
            client.on("connect", sendSingleDevice)
            :
            client.on("connect", sendAllDevice)
    }

    return (
        <>
            <ToastContainer position="top-center" />
            <div className="body d-flex py-3">
                <div className="container-xxl">
                    <div className="row align-items-center">
                        <div className="border-0 mb-4">
                            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                                <h3 className="fw-bold mb-0">Publish Topic</h3>
                            </div>
                        </div>
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="device" id="all" value="all" onChange={onOptionChange} checked={option === "all"} />
                        <label className="form-check-label" htmlFor="all">
                            All device
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="device" id="single" value="single" onChange={onOptionChange} checked={option === "single"} />
                        <label className="form-check-label" htmlFor="single">
                            Single Device
                        </label>
                    </div>


                    <div className="row g-3 my-2">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card mb-3">
                                <div className="card-body">

                                    <form id="my-form" onSubmit={MqttPublish}>


                                        <div className="row g-3 align-items-center">
                                            {
                                                option === "single" ?
                                                    <div className="col-md-12">
                                                        <label className="form-label">Device ID</label>
                                                        <input type="text" className="form-control" name='gateway_id' value={gateway_id} onChange={(e) => setGateway_id(e.target.value)} required />
                                                    </div>
                                                    : ""
                                            }

                                            <div className="col-md-6">
                                                <label className="form-label">Topic</label>
                                                <input type="text" className="form-control" name='mqtt_topic' value={mqtttopic} onChange={(e) => setMqtttopic(e.target.value)} required />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">MQTT Message</label>
                                                <input type="text" className="form-control" name='mqtt_message' value={mqttmessage} onChange={(e) => setMqttmessage(e.target.value)} required />
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-4 text-uppercase px-5">Publish</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MqttComp