import axios from "axios";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import avatar from '../../assets/avatar3.svg'
import { Link } from "react-router-dom"
import Loader from "../loader/Loader";




const CustDetails = ({ cust }) => {

  const fireBaseTime = new Date(
    cust.CreatedAt._seconds * 1000 + cust.CreatedAt._nanoseconds / 1000000,
  )

  const date = fireBaseTime.toDateString();
  const [usergateway, setUsergateway] = useState()
  const [userloginlog, setUserloginlog] = useState()
  // const [userlogincount,setUserlogincount]=useState()
  const [isloading, setIsloading] = useState(true)
  const [islogloading, setIslogloading] = useState(true)


  useEffect(() => {
    const fetchCustomergateway = async () => {
      const config = {
        headers: {
          'Authorization': `${Cookies.get('Authorization')}`,
        }
      };

      const gateways = await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/organizationgateways', {
        "OrganizationID": cust.OrganizationID
      }, config)
      setUsergateway(gateways.data)
      setIsloading(false)
    }

    fetchCustomergateway()
  }, [cust.OrganizationID])

  useEffect(() => {
    const fetchloginlog = async () => {

      try {
        const config = {
          headers: {
            'Authorization': `${Cookies.get('Authorization')}`,
          }
        };
        setIslogloading(false)
        const userlog = await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/loginlog', {
          "username": cust.username
        }, config)

        setUserloginlog(userlog.data.Items)
        setIslogloading(false)

        // setUserlogincount(userlog.data.Count)

      } catch (error) {

        console.log(error)

      }

    }

    fetchloginlog()
  }, [cust.username])

  return (
    <>
      <div className="body d-flex py-3">


        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0">Customer Detail</h3>
              </div>
            </div>
          </div>

          <div className="row g-3 mb-xl-3">
            <div className="col">
              <div className="card profile-card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold ">Profile</h6>
                </div>
                <div className="card-body d-flex profile-fulldeatil flex-column">
                  <div className="profile-block text-center w220 mx-auto">
                    {/* <img src={cust.ProfileURI ? cust.ProfileURI : avatar} alt="" className="avatar xl rounded img-thumbnail shadow-sm" /> */}
                    <div className="about-info d-flex align-items-center mt-3 justify-content-center flex-column">
                      <span className="text-muted small">ID : {cust.OrganizationID}</span>
                    </div>
                  </div>
                  <div className="profile-info w-100">
                    <h6 className="mb-0 mt-2  fw-bold d-block fs-6 text-center"> {cust.username}</h6>
                    <div className="row g-2 pt-2">
                      <div className="col-xl-12">
                        <div className="d-flex align-items-center">
                          <i className="icofont-email"></i>
                          <span className="ms-2"><strong>Email:</strong> {cust.Email}</span>

                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="d-flex align-items-center">
                          <i className="icofont-birthday-cake"></i>
                          <span className="ms-2"><strong>Created at:</strong> {date}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card auth-detailblock">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold ">Login log</h6>
                </div>
                <div className="card-body" style={{ overflow: "scroll", height: "33vh" }}>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {islogloading ? <Loader /> :

                          userloginlog?.map((data) => {
                            return (

                              <tr key={data.logintime}>
                                <td>{data.logintime}</td>
                              </tr>
                            )
                          })
                        }

                      </tbody>

                    </table>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="row">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0">Gateway Details</h3>
              </div>

            </div>
            {
              isloading ? <Loader /> :
                usergateway.map((data) => {
                  const fireBaseTime = new Date(
                    data.CreatedAt._seconds * 1000 + cust.CreatedAt._nanoseconds / 1000000,
                  )
                  const gatewaydate = fireBaseTime.toDateString();

                  return (
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12" key={data.gatewayID}>
                      <Link to={`/gatewayinfo/${data.gatewayID}`} style={{ textDecoration: "none" }}>
                        <div className="card  mb-3" >
                          <div className="card-header"> <h6>{data.gatewayID} </h6></div>
                          <div className="card-body">
                            <p className="card-title"> <strong>Gateway Name:</strong> {data.name}<br /> <strong>Created At:</strong> {gatewaydate}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
            }
          </div>
        </div>


      </div>
    </>
  )
}

export default CustDetails