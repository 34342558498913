import axios from "axios"
import Cookies from "js-cookie"
import { useEffect } from "react"
import { useState } from "react"
import List from "./List"
import Loader from '../loader'

const NewRegisterComp = () => {
    const [newusers, setNewusers] = useState([])
    const [isloading, setIsloading] = useState(true)
    useEffect(() => {
        const fetchnewusers = async () => {

            const config = {
                headers: {
                    'Authorization': `${Cookies.get('Authorization')}`,
                }
            };
            const fetchedusers = await axios.get('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/allregister', config)
            setNewusers(fetchedusers.data)
            setIsloading(false)

        }

        fetchnewusers()

    }, [])


    return (
        <>
            <div className="row g-3 mb-3">
                <div className="col-md-12 col-sm-12">
                    <div className="card">
                        <div
                            className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                            <h6 className="m-0 fw-bold">New Registers</h6>
                        </div>
                        {isloading ? <Loader /> :

                            <div className="card-body">
                                <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Username</th>
                                                        <th scope="col">Registered At</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        newusers.map((data) => {
                                                            return (
                                                                <List key={data.username} registerer={data} />
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default NewRegisterComp