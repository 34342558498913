import { useEffect } from 'react'
import Header from '../../components/header'
import NewGatewayComp from '../../components/newgatewaycomp'
import Sidebar from '../../components/sidebar'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

const NewGateway = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const cookie = Cookies.get('Authorization')

        if (!cookie) {
            navigate("/")
        }

    })
    
    return (
        <>
            <div id="ebazar-layout" className="theme-blush">
                <Sidebar />

                <div className="main px-lg-4 px-md-4">
                    <Header />
                    <NewGatewayComp />
                </div>
            </div>
        </>
    )
}

export default NewGateway
