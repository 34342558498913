import React from "react";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import { GrRefresh } from "react-icons/gr";

import AWS from "aws-sdk";
import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
// import * as MQTT from "precompiled-mqtt";
// import * as MQTT from "mqtt/dist/mqtt.min";
import mqtt from 'mqtt'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// import CryptoJS from "crypto-js";

const Bin = () => {
  const [file, setFile] = useState(null);
  const [versionFile, setVersionFile] = useState(null);
  const [binFileUploaded, setBinFileUploaded] = useState(false);
  const [otasuccessful, setOtasuccessful] = useState(false);

  const [fileProgressBar, setFileProgressBar] = useState(0);
  const [versionfileProgressBar, setVersionFileProgressBar] = useState(0);

  const [option, setOption] = useState("single");

  const [gateway_id, setGateway_id] = useState(null);
  const [gatewayids, setGatewayids] = useState([]);
  const [gatewaysync, setGatewaysync] = useState([])
  const [unpublishGateways, setUnpublishGateways] = useState([])

  const [gatewaysafterOta, setGatewaysafterOta] = useState([])

  // const key = "RKTJXLYCOFSWVTHX"
  // const text = "The book is in front of the table. Nudist colonies shun fig-leaf couture. Most shark attacks occur about 10 feet from the beach since that's where the people are.As the asteroid hurtled toward earth, Becky was upset her dentist appointment had been canceled. Potato wedges probably are not best for relationships. The quick brown fox jumps over the lazy dog. Please wait outside of the house. For some unfathomable reason, the response team didn't consider a lack of milk for my cereal as a proper emergency. Iron pyrite is the most foolish of all minerals. Siri became confused when we reused to follow her directions.";
  // var encryptedData = CryptoJS.AES.encrypt("RKTJXLYCOFSWVTHX", key, {
  //   mode: CryptoJS.mode.CBC,
  //   padding: CryptoJS.pad.Pkcs7,
  // });

  // const ciphertext = encryptedData.ciphertext.toString();
  // console.log("Encrypted Data:",encryptedData.toString())
  // console.log(ciphertext);


  const onOptionChange = (e) => {
    setOption(e.target.value);
  }
  const resetForm = () => {
    setGateway_id(null);
  }

  useEffect(() => {
    const fetchgateway = async () => {
      const config = {
        headers: {
          Authorization: `${Cookies.get("Authorization")}`,
        },
      };
      const fetchedgateways = await axios.get(
        "https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/allgateway",
        config
      );
      let gatewayIds = fetchedgateways.data.map((gateway) => gateway.gatewayID);
      setGatewayids(gatewayIds);
    };
    fetchgateway();
  }, [])

  useEffect(() => {
    const fetchgatewaysync = async () => {
      const config = {
        headers: {
          Authorization: `${Cookies.get("Authorization")}`,
        },
      };
      const fetchedgateways = await axios.get(
        "https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/gatewaysyncstatus",
        config
      );
      // let gatewayIds = fetchedgateways.data.map((gateway) => gateway.gatewayID);
      // setGatewayids(gatewayIds);
      setGatewaysync(fetchedgateways.data)

    };

    fetchgatewaysync();
  }, [])


  const getGateways = async () => {

    if (option === "single") {
      const config = {
        headers: {
          'Authorization': `${Cookies.get('Authorization')}`,
        }
      };
      console.log(gateway_id)
      const gatewayinfo = await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/gatewayinfo', {
        "GatewayID": gateway_id
      }, config)
      
     
      setGatewaysafterOta([gatewayinfo.data]);
    }


    else {
      const config = {
        headers: {
          Authorization: `${Cookies.get("Authorization")}`,
        },
      };
      const fetchedgateways = await axios.get(
        "https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/allgateway",
        config
      );

      setGatewaysafterOta(fetchedgateways.data);
    }

  }



  const UploadBinFile = (event) => {
    setFile(event.target.files[0]);
  }

  const UploadVersionFile = (event) => {
    setVersionFile(event.target.files[0]);
  }

  const handleUpload = async (e) => {
    e.preventDefault();

    const S3_BUCKET = "encryptedotabin";
    const REGION = "ap-south-1";

    AWS.config.update({
      accessKeyId: "AKIAQRZ7237UCEGYDM5O",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: file.name,
      Body: file,
    };

    const params1 = {
      Bucket: S3_BUCKET,
      Key: versionFile.name,
      Body: versionFile,
    };

    let upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setFileProgressBar(parseInt((evt.loaded * 100) / evt.total));
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
    });

    let versionupload = s3
      .putObject(params1)
      .on("httpUploadProgress", (evt) => {
        setVersionFileProgressBar(parseInt((evt.loaded * 100) / evt.total));
      })
      .promise();

    await versionupload.then((err, data) => {
      console.log(err);
    });

    setBinFileUploaded(true);
  }

  const getSyncStatusForGatewayID = (array, gatewayID) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].GatewayID === gatewayID) {
        return array[i].sync
      }
    }
    return null
  }


  const MqttPublish = (e) => {
    e.preventDefault();

    let opt = {
      clientId: "iotconsole-5c3648b6-ac9e-4ca5-82e1-d8116c2c6f22",
      clean: true,
      username: "AKIASLNTBIH665RLMI3D",
      password: "HjxWWI/aZRnv+Ih4nNHQ+j9wDUjzoe2FHkItQxuj",
      reconnectPeriod: 0,
      protocolId: "MQTT",
      protocolVersion: 5,
      port: 443,
    };

    let client = mqtt.connect(
      "wss://a381jvamqe2nox-ats.iot.ap-south-1.amazonaws.com/mqtt?x-amz-customauthorizer-name=mqttAuthorizer",
      opt
    );


    const sendSingleDevice = () => {
      let topic = "*gateway/" + gateway_id + "/encrypted/fota"
      let message = "**gateway/" + gateway_id + "/encrypted/fota/begin"

      let syncStatus = getSyncStatusForGatewayID(gatewaysync, gateway_id)
      if (syncStatus) {
        setUnpublishGateways([gateway_id])
      }

      else {
        try {
          client.publish(topic, message, { retain: true }, (err) => {
            if (err) {
              toast.error(`OTA failed ${gateway_id}`, {
                icon: "❌",
              });
            }
          });

          toast.success(
            `OTA instruction succefully published for Gateway ${gateway_id}`,
            {
              icon: "🚀",
            }
          );
          setOtasuccessful(true)
          client.end();
          // resetForm();
        } catch (error) {
          toast.error(error);
        }
      }
    };


    const sendAllDevice = () => {

      const filteredGateways = gatewaysync.filter(item => gatewayids.includes(item.GatewayID) && !item.sync);
      const gatewayIDsWithFalseSync = filteredGateways.map(item => item.GatewayID);

      const excludedGatewayIDs = gatewayids.filter(id => !gatewayIDsWithFalseSync.includes(id));
      setUnpublishGateways(excludedGatewayIDs)

      try {
        gatewayIDsWithFalseSync.map((deviceid) =>
          client.publish("*gateway/" + deviceid + "/encrypted/fota", "**gateway/" + deviceid + "/encrypted/fota/begin", { retain: true })
        );
        toast.success("OTA instruction succefully published for eligable gateways.", {
          icon: "🚀",
        });
        setOtasuccessful(true)
        // resetForm();
      } catch (error) {
        toast.error(error);
      }

    };

    option === "single"
      ? client.on("connect", sendSingleDevice)
      : client.on("connect", sendAllDevice)
  }

  return (
    <div id="ebazar-layout" className="theme-blush">
      <Sidebar />
      <div className="main px-lg-4 px-md-4">
        <Header />
        <div className="body d-flex py-3">
          <div className="container-xxl">
            <div className="row align-items-center">
              <div className="border-0 mb-4">
                <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                  <h3 className="fw-bold mb-0">Over The Air(OTA) Update</h3>
                </div>
              </div>
            </div>

            <div className="row g-3 my-2">
              <div className="col-xl-12 col-lg-12">
                <div className="card mb-3">
                  <div className="card-body">
                    <form id="my-form">
                      <div className="row g-3 align-items-center">
                        <div className="col-md-12">
                          <label className="form-label">
                            Bin file
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="encrypted_file"
                            onChange={UploadBinFile}
                            required
                          />
                          {file ? (
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Animated striped example"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ height: "10px" }}
                            >
                              <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                style={{ width: `${fileProgressBar}%` }}
                              >
                                {fileProgressBar}%
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <br />
                          <label className="form-label">Verison file</label>
                          <input
                            type="file"
                            className="form-control"
                            name="version_file"
                            onChange={UploadVersionFile}
                            required
                          />

                          {versionFile ? (
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Animated striped example"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ height: "10px" }}
                            >
                              <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                style={{ width: `${versionfileProgressBar}%` }}
                              >
                                {versionfileProgressBar}%
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {file && versionFile ? (
                        <button
                          type="submit"
                          className="btn btn-primary mt-4 text-uppercase px-5"
                          onClick={handleUpload}
                        >
                          Upload
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary mt-4 text-uppercase px-5"
                          disabled
                        >
                          Upload
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {binFileUploaded ? (

              <div className="row g-3 my-2">
                {
                  unpublishGateways.map((gateway) => (<p> ❌ {gateway} is currently sync.</p>))
                }
                <div className="col-xl-12 col-lg-12">
                  <div className="card mb-3">
                    <ToastContainer position="top-center" />

                    <div className="card-body">
                      <form id="my-form">
                        <div className="d-grid gap-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="device"
                              id="all"
                              value="all"
                              onChange={onOptionChange}
                              checked={option === "all"}
                            />
                            <label className="form-check-label" htmlFor="all">
                              All device
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="device"
                              id="single"
                              value="single"
                              onChange={onOptionChange}
                              checked={option === "single"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="single"
                            >
                              Single Device
                            </label>
                          </div>

                          {option === "single" ? (
                            <div className="col-md-12">
                              <label className="form-label">Device ID</label>
                              <input
                                type="text"
                                className="form-control"
                                name="gateway_id"
                                value={gateway_id}
                                onChange={(e) => setGateway_id(e.target.value)}
                                required
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={MqttPublish}
                          >
                            OTA
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* {
              otasuccessful ? (
                <div className="row g-3 my-2">
                  <div className="col-xl-12 col-lg-12">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="row">
                                <div className="col-sm-12 col-md-12">
                                  <GrRefresh onClick={getGateways} style={{cursor:"pointer"}}/>
                                </div>
                              </div>
                              <div className="row">

                                <table className="table table-hover">
                                  <thead>
                                    <tr>
                                      <th scope="col">Gateway ID</th>
                                      <th scope="col">Gateway version</th>
                                      
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      gatewaysafterOta.map((data) => {

                                        return (
                                          <tr key={data.gatewayID}>
                                            <th scope="row">{data.gatewayID}</th>
                                          
                                            <td>{data.gatewayversion}</td>
                                           
                                          </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                                </table>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) :
                ("")
            } */}


          </div>
        </div>
      </div>
    </div>

  );
};

export default Bin;
