import { useState,useContext, useEffect } from 'react';
import './Signin.css'
import { CognitoUserPool, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../helper/Context';


const Signin = () => {
    const[username,setUsername]=useState()
    const[password,setPassword]=useState()
    const[usernameisset,setUsernameisset]=useState()
    const[passwordisset,setPasswordisset]=useState()
    const [authenticated,setAuthenticated]=useState()

    const {setUser}=useContext(UserContext)
    let navigate = useNavigate();
    
    
    useEffect(() => {
      const cookie = Cookies.get('Authorization')

      if (cookie) {
          navigate("/dashboard")
      }

  })
    const poolData = {
        UserPoolId: 'ap-south-1_fVxvFfNRz',
        ClientId: '2kkhc6onihn7muj66j2ifj9fd2'  
      };
      
      const userPool = new CognitoUserPool(poolData);

    // Sign Up 
      // const attributeList = [
      //   new CognitoUserAttribute({ Name: 'email', Value: 'amamit.in@gmail.com' }),
      //   new CognitoUserAttribute({ Name: 'name', Value: 'amamit' }),
      // ];

      // userPool.signUp('amamit', 'amamit2K23!', attributeList, null, (err, result) => {
      //   if (err) {
      //     console.log(err);
      //     return;
      //   }
      //   console.log('user signed up successfully');
      // });


    const cognitoValidate=(e)=>{
        e.preventDefault();
        !username?setUsernameisset(0):setUsernameisset(1)
        
        !password?setPasswordisset(0):setPasswordisset(1)
        setAuthenticated(1)
        if(!username || !password){
            return
        }

        const user = new CognitoUser({
            Username: username,
            Pool: userPool,
          });
          
          
          const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
          });
    
          user.authenticateUser( authDetails, {
            onSuccess: (result) => {
           
              localStorage.setItem("admin",result.getAccessToken().decodePayload().username);
              Cookies.set('Authorization', `${result.getIdToken().getJwtToken()}`, { expires: 1 })
              setUser({'Authorization':`${result.getIdToken().getJwtToken()}`})
              navigate('dashboard')
            },
            onFailure: (err) => {
              setAuthenticated(0)
            },
          });
    }

    return (
        <>
        <div className="loginbody">

            <div className="striped-container">
                <form className='login-form'>
                    <h1 className='login-header'>Sign In.</h1>
                    { authenticated===0 ? <p style={{color:'red'}}>* Wrong username/password.</p>:""}
                    <label htmlFor="username" className='login-label'>Username</label>
                    { usernameisset===0 ? <p style={{color:'#1b617d'}}>Username is required.</p>:""}

                    <input type="text" onChange={(e)=>setUsername(e.target.value)} className='login-input'/>

                    <label htmlFor="password" className='login-label'>Password</label>
                    { passwordisset===0 ? <p style={{color:'#1b617d'}}>Password is required.</p>:""}
                    <input type="password" onChange={(e)=>setPassword(e.target.value)} className='login-input'/>
                    <button className="login-button ripple" data-ripple-color="white" onClick={cognitoValidate}>GO.</button>
                </form>
            </div>
        </div>
        </>
    )
}

export default Signin