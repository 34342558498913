import './loader.css'

const Loader = () => {
  return (
    <div className='loader-holder'>

    <div className="loader"></div>
    </div>
  )
}

export default Loader