import { Link } from "react-router-dom"

const List = ({ gateway }) => {
    
    const fireBaseTime = new Date(
        gateway.CreatedAt._seconds * 1000 + gateway.CreatedAt._nanoseconds / 1000000,
    );
    const date = fireBaseTime.toDateString();

    return (
        <>
            <tr>
                <th scope="row">{gateway.gatewayID}</th>
                <td>{gateway.username}</td>
                <td>{gateway.Address}</td>
                <td>{gateway.name}</td>
                <td>{date}</td>

               
                    <td> <Link to={`/gatewayinfo/${gateway.gatewayID}`} style={{ textDecoration: "none" }}>Details </Link></td>
               

            </tr>
        </>
    )
}

export default List