import Header from "../../components/header/Header"
import NewRegisterComp from "../../components/newregistercomp"
import Sidebar from "../../components/sidebar/Sidebar"


const NewRegisters = () => {
  return (
    <>
      <div id="ebazar-layout" className="theme-blush"> 
       <Sidebar/> 
       <div className="main px-lg-4 px-md-4">
          <Header/>
          <NewRegisterComp/>   
       </div>
      </div>
    </>
  )
}

export default NewRegisters