import { Link } from "react-router-dom"

const List = ({ registerer }) => {
    const fireBaseTime = new Date(
        registerer.CreatedAt._seconds * 1000 + registerer.CreatedAt._nanoseconds / 1000000,
    );
    const date = fireBaseTime.toDateString();

    return (
        <>
            <tr>
                <th scope="row">{registerer.Email}</th>
                <td>@{registerer.username}</td>
                <td>{date}</td>
                <td> <button  className="btn btn-warning py-2 px-5 text-uppercase "><Link to={`/newregister/${registerer.RegistrationID}`} style={{ textDecoration: "none" }}>Assign Gateway</Link> </button></td>
            </tr>
        </>
    )
}

export default List