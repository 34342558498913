
const Node = ({ node, no }) => {
    return (
        <>
            <div className="col">
                <div className="card auth-detailblock">
                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                        <h5 className="mb-0 fw-bold ">Node {no}</h5>
                    </div>
                    <div className="card-body">
                        <div className="row g-3">
                            <div className="col-12">
                                <label className="form-label col-6 col-sm-5">Name:</label>
                                <span>{node.name}</span>
                            </div>
                            <div className="col-12">
                                <label className="form-label col-6 col-sm-5">MAC:</label>
                                <span>{node.mac}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Node