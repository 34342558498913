import Header from "../../components/header"
import NewRegisterDetails from "../../components/newregisterdetails"
import Sidebar from "../../components/sidebar"

const NewRegisterInfo = () => {
  return (
    <>
    <div id="ebazar-layout" className="theme-blush"> 
       <Sidebar/> 
       <div className="main px-lg-4 px-md-4">
          <Header/>
          <NewRegisterDetails/>
       </div>
      </div>
    </>
  )
}

export default NewRegisterInfo