import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Sidebar from "../../components/sidebar"
import Header from "../../components/header"
import GatewayDetails from "../../components/gatewaydetails"
import Cookies from "js-cookie"
import Loader from "../../components/loader/Loader"



const GatewayInfo = () => {
  // const {user}=useContext(UserContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const cookie = Cookies.get('Authorization')

    if (!cookie) {
      navigate("/")
    }

  })
  const { gatewayid } = useParams()
  const [gateway, setGateway] = useState()
  useEffect(() => {
    const getGatewayInfo = async () => {

      const config = {
        headers: {
          'Authorization': `${Cookies.get('Authorization')}`,
        }
      };

      const gatewayinfo = await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/gatewayinfo', {
        "GatewayID": gatewayid
      }, config)
      setGateway(gatewayinfo.data)
      setIsLoading(false)
    }

    setIsLoading(true)
    getGatewayInfo()
  }, [gatewayid])

  return (
    <>


      <div id="ebazar-layout" className="theme-blush">
        <Sidebar />
        <div className="main px-lg-4 px-md-4">
          <Header />
          {
            isLoading ? <Loader /> : <GatewayDetails gateway={gateway} />
          }
        </div>
      </div>
    </>
  )
}

export default GatewayInfo