import BodyComp from '../../components/bodycomp'
import Header from '../../components/header'
import Sidebar from '../../components/sidebar'
import {useEffect } from 'react';
// import { UserContext } from '../../helper/Context';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


const Home = () => {

  // const {user}=useContext(UserContext)
  const navigate=useNavigate()
  
  useEffect(()=>{
   const cookie= Cookies.get('Authorization')
   
    if(!cookie){
      navigate("/")
    }

  })
  return (
    
    <>
    
      <div id="ebazar-layout" className="theme-blush"> 
       <Sidebar/> 
       
       <div className="main px-lg-4 px-md-4">
          <Header/>
          <BodyComp/>
       </div>
      </div>
    </>
  )
}

export default Home