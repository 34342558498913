import { useState } from "react"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from "axios"
import Cookies from "js-cookie"


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InvoiceComp = () => {
    const [organizationID, setOrganizationID] = useState("");
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [orgfound, setOrgfound] = useState(false)
    const handelOrganizationClick = async (event) => {
        event.preventDefault()

        const config = {
            headers: {
                'Authorization': `${Cookies.get('Authorization')}`,
            }
        };

        const client = await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/clientbyemail', {
            Email: email
        }, config)

        if (client.data.length === 0) {
            toast.error("No client is found associated with this email address.", {
                position: toast.POSITION.TOP_CENTER
            })
        }
        else {
            setOrganizationID(client.data[0].OrganizationID)
            setUsername(client.data[0].username)
            setOrgfound(true)
        }

    };

    return (
        <>
            <ToastContainer />
            <div className="body d-flex py-3">
                <div className="container-xxl">
                    <div className="row align-items-center">
                        <div className="border-0 mb-4">
                            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                                <h3 className="fw-bold mb-0">Generate invoice</h3>
                                <button type="submit" className="btn btn-primary btn-set-task w-sm-100 py-2 px-5 text-uppercase" form="my-form">Save</button>
                            </div>
                        </div>
                    </div>

                    <form>
                        <div className="row g-3 mb-3">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card mb-3">
                                    <div className="card-body">

                                        <div className="row g-3 align-items-center">
                                            <div className="col-md-12 ">
                                                <label className="form-label">Email</label>
                                                <div className='input-group'>
                                                    <input type="email" className="form-control" name='email' aria-label="Field" aria-describedby="tick-button" onChange={(e) => setEmail(e.target.value)} required />
                                                    <button className="btn tick-button" type="button" id="tick-button" onClick={handelOrganizationClick} >
                                                        &#10004;
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <label className="form-label">username</label>
                                                <input type="text" className="form-control" name='username' value={username} readOnly />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label">Organization ID</label>
                                                <input type="text" className="form-control" name='OrganizationID' value={organizationID} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {
                        orgfound ?
                            <Formik initialValues={{
                                custid: organizationID,
                                username: username,
                                email: email,

                                from: '',
                                to: '',
                                due: '',

                                amount: '',
                                discount: ''
                            }}

                                validationSchema={Yup.object({

                                    from: Yup.date()
                                        .required('Required'),
                                    to: Yup.date()
                                        .required('Required'),
                                    due: Yup.date().required('Required'),
                                    amount: Yup.number().positive().integer().required('Required'),
                                    discount: Yup.number().required('Required').positive().integer().min(0).max(100)
                                })}

                                onSubmit={async (values) => {
                                    const params = {
                                        custid: values.custid,
                                        username: values.username,
                                        email: values.email,

                                        from: values.from,
                                        to: values.to,
                                        due: values.due,

                                        amount: values.amount,
                                        discount: values.discount,
                                    }
                                    try {
                                        const config = {
                                            headers: {
                                                'Authorization': `${Cookies.get('Authorization')}`,
                                            }
                                        };

                                        let response = await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/generateinvoice', params, config)

                                        if (response.status === 200) {
                                            toast("Invoice generated", {
                                                position: toast.POSITION.TOP_CENTER
                                            })
                                        }
                                        else {
                                            toast("Something went wrong.", {
                                                position: toast.POSITION.TOP_CENTER
                                            })
                                        }
                                    }
                                    catch (error) {
                                        toast.error(error, {
                                            position: toast.POSITION.TOP_CENTER
                                        })
                                    }
                                }}>

                                <Form id="my-form">
                                    <div className="row g-3 mb-3">
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-md-6">
                                                            <label className="form-label">
                                                                From
                                                            </label>
                                                            <Field
                                                                type="date"
                                                                id="from"
                                                                name="from"
                                                                className='form-control'
                                                                required
                                                            />
                                                            <ErrorMessage name="from" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">
                                                                To
                                                            </label>
                                                            <Field
                                                                type="date"
                                                                id="to"
                                                                name="to"
                                                                className='form-control'
                                                                required
                                                            />
                                                            <ErrorMessage name="to" />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label className="form-label">
                                                                Bill
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                min="0"
                                                                id="amount"
                                                                name="amount"
                                                                className='form-control'
                                                                required
                                                            />
                                                            <ErrorMessage name="amount" />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label className="form-label">
                                                                Discount
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                min="0"
                                                                max="100"
                                                                id="discount"
                                                                name="discount"
                                                                className='form-control'
                                                                required
                                                            />
                                                            <ErrorMessage name="discount" />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label className="form-label">
                                                                Due date
                                                            </label>
                                                            <Field
                                                                type="date"
                                                                id="due"
                                                                name="due"
                                                                className='form-control'
                                                                required
                                                            />
                                                            <ErrorMessage name="due" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Formik> : ""
                    }


                </div>
            </div>



        </>
    )
}

export default InvoiceComp