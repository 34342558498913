import axios from "axios"
import { useEffect, useState } from "react"
import List from "./List"
import Cookies from "js-cookie"
import Loader from "../../loader"
import { BiSolidDownload } from "react-icons/bi"
import xlsx from "json-as-xlsx"


const Gateways = () => {
    const [allgateway, setAllGateway] = useState([])
    const [isloading, setIsloading] = useState(true)
    useEffect(() => {

        const fetchgateway = async () => {

            const config = {
                headers: {
                    'Authorization': `${Cookies.get('Authorization')}`,
                }
            };
            const fetchedgateways = await axios.get('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/allgateway', config)
            setAllGateway(fetchedgateways.data)
            setIsloading(false)
        }
        setIsloading(true)
        fetchgateway()

    }, [])

    const downloadXlsx = () => {
        let settings = {
            fileName: "MySpreadsheet", 
            extraLength: 3, 
            writeMode: "writeFile", 
            writeOptions: {}, 
            RTL: false
        }

        let data = [{
            sheet: "Gateways",
            columns: [
                { label: "Gateway ID", value: "gatewayID" }, // Top level data
                { label: "OrganizationID", value: "OrganizationID" }, // Custom format
                { label: "Gateway name", value: "name" },
                { label: "Address", value: "Address" }
            ],
            content: allgateway,
        }]
        xlsx(data, settings)
    }
    return (
        <>
            <div className="row g-3 mb-3">
                <div className="col-md-12 col-sm-12">
                    <div className="card">
                        <div
                            className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                            <h6 className="m-0 fw-bold">All Gateways</h6>
                            <BiSolidDownload onClick={downloadXlsx} style={{"cursor": "pointer"}} />

                        </div>
                        {isloading ? <Loader />
                            :
                            <div className="card-body">
                                <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Gateway ID</th>
                                                        <th scope="col">Username</th>
                                                        <th scope="col">locatiion</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col"> Created At</th>
                                                        <th scope="col"> #</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allgateway.map((data) => {
                                                        return (
                                                            <List key={data.gatewayID} gateway={data} />
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Gateways