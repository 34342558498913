import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from './Card'
import Cookies from 'js-cookie';
import { useState } from 'react';



const NewGatewayComp = () => {
    const [organizationID, setOrganizationID] = useState("");
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")

    const [orgfound, setOrgfound] = useState(false)

    const handelOrganizationClick = async (event) => {
        event.preventDefault()

        const config = {
            headers: {
                'Authorization': `${Cookies.get('Authorization')}`,
            }
        };

        const client = await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/clientbyemail', {
            Email: email
        }, config)

        if (client.data.length === 0) {
            toast.error("No client is found associated with this email address.", {
                position: toast.POSITION.TOP_CENTER
            })
        }
        else {
            setOrganizationID(client.data[0].OrganizationID)
            setUsername(client.data[0].username)
            setOrgfound(true)
        }

    };

    return (
        <>
            <ToastContainer />
            <div className="body d-flex py-3">
                <div className="container-xxl">

                    <div className="row align-items-center">
                        <div className="border-0 mb-4">
                            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                                <h3 className="fw-bold mb-0">Add Gateway</h3>
                                <button type="submit" className="btn btn-primary btn-set-task w-sm-100 py-2 px-5 text-uppercase" form="my-form">Save</button>
                            </div>
                        </div>
                    </div>

                    <form>
                        <div className="row g-3 mb-3">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card mb-3">
                                    <div className="card-body">

                                        <div className="row g-3 align-items-center">
                                            <div className="col-md-12 ">
                                                <label className="form-label">Email</label>
                                                <div className='input-group'>
                                                    <input type="email" className="form-control" name='email' aria-label="Field" aria-describedby="tick-button" onChange={(e) => setEmail(e.target.value)} required />
                                                    <button className="btn tick-button" type="button" id="tick-button" onClick={handelOrganizationClick} >
                                                        &#10004;
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <label className="form-label">username</label>
                                                <input type="text" className="form-control" name='username' value={username} readOnly />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label">Organization ID</label>
                                                <input type="text" className="form-control" name='OrganizationID' value={organizationID} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    
                    {
                        orgfound ?
                            <Formik

                                initialValues={{
                                    OrganizationID: organizationID,
                                    username: username,
                                    Email: email,

                                    gatewayID: '',
                                    Address: '',
                                    GatewayName: '',

                                   
                                    node1_ctval: '',
                                    node2_ctval: '',
                                    node3_ctval: '',
                                    node4_ctval: '',

                                    avg_bill: '',
                                    avg_conditioning_capacity: '',
                                    space_size: '',
                                    air_conditioned_area: '',
                                    geographical_location: '',
                                    operating_hours: '',

                                }}

                                validationSchema={Yup.object({
                                    gatewayID: Yup.string()
                                        .max(20, 'Must be 20 characters or less')
                                        .min(5, 'Must be 5 characters or more')
                                        .required('Required'),

                                    node1_ctval: Yup.number()
                                        .min(0)
                                        .max(1000),

                                    node2_ctval: Yup.number()
                                        .min(0)
                                        .max(1000),
                                    
                                    node3_ctval: Yup.number()
                                        .min(0)
                                        .max(1000),

                                    node4_ctval: Yup.number()
                                        .min(0)
                                        .max(1000),      
                                        
                                    Address: Yup.string()
                                        .required('Required'),

                                    GatewayName: Yup.string()
                                        .required("Please provide a gateway name"),

                                    avg_bill: Yup.number()
                                        .required("required").positive().integer(),

                                    avg_conditioning_capacity: Yup.string()
                                        .required("required"),

                                    space_size: Yup.number()
                                        .required("required"),

                                    air_conditioned_area: Yup.number()
                                        .required("required"),

                                    geographical_location: Yup.number()
                                        .required("required"),

                                    operating_hours: Yup.number()
                                        .required("required"),
                                })}

                                onSubmit={async (values) => {
                                    const params = {
                                        "OrganizationID": organizationID,
                                        "Email": email,
                                        "username": username,
                                        "Address": values.Address,
                                        "gatewayID": values.gatewayID,
                                        "name": values.GatewayName,
                                        "node1": {
                                            "ctval": values?.node1_ctval || ""
                                        },
                                        "node2": {
                                            "ctval": values?.node2_ctval || ""
                                        },
                                        "node3": {
                                            "ctval": values?.node3_ctval || ""
                                        },
                                        "node4": {
                                            "ctval": values?.node4_ctval || ""
                                        },
                                        "calculator_parameters": {
                                            "avg_bill": values.avg_bill,
                                            "avg_conditioning_capacity": values.avg_conditioning_capacity,
                                            "space_size": values.space_size,
                                            "air_conditioned_area": values.air_conditioned_area,
                                            "geographical_location": values.geographical_location,
                                            "operating_hours": values.operating_hours,
                                        }
                                    }

                                    try {
                                        const config = {
                                            headers: {
                                                'Authorization': `${Cookies.get('Authorization')}`,
                                            }
                                        };

                                        let response = await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/newgateway', params, config)

                                        if (response.status === 200) {
                                            toast(<Card newgateway={params} />, {
                                                position: toast.POSITION.TOP_CENTER
                                            })
                                        }
                                        else {
                                            toast("Something went wrong.", {
                                                position: toast.POSITION.TOP_CENTER
                                            })
                                        }
                                    }
                                    catch (error) {
                                        toast.error(error, {
                                            position: toast.POSITION.TOP_CENTER
                                        })
                                    }
                                }}
                            >

                                <Form id="my-form">
                                    <div className="row g-3 mb-3">
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="card mb-3">
                                                <div className="card-body">

                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-md-12">
                                                            <label className="form-label">Gateway ID &nbsp;</label>
                                                            <label className="form-label" style={{ "color": "red" }}>
                                                                <ErrorMessage name="gatewayID" />
                                                            </label>
                                                            <Field type="text" className="form-control" name='gatewayID' />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label className="form-label">Gateway Address  &nbsp;</label>
                                                            <label className='form-label' style={{ "color": "red" }}>
                                                                <ErrorMessage name="Address" />
                                                            </label>

                                                            <Field type="text" className="form-control" name='Address' />

                                                        </div>

                                                        <div className="col-md-12">
                                                            <label className="form-label">Gateway Name  &nbsp;</label>
                                                            <label className="form-label" style={{ "color": "red" }}>
                                                                <ErrorMessage name="GatewayName" style={{ "color": "red" }} />
                                                            </label>

                                                            <Field type="text" className="form-control" name='GatewayName' />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <h6>Node 1</h6>
                                                        <div className="col-md-12">
                                                            <label className="form-label">CT turn ratio &nbsp;</label>
                                                            <label className="form-label" style={{ "color": "red" }}>

                                                                <ErrorMessage name="node1_ctval" />
                                                            </label>
                                                            <Field type="number" min="0" max="1000" className="form-control" name='node1_ctval' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <h6>Node 2</h6>
                                                        <div className="col-md-12">
                                                            <label className="form-label">CT turn ratio</label>

                                                            <Field type="number" min="0" max="1000" className="form-control" name='node2_ctval' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <h6>Node 3</h6>
                                                        <div className="col-md-12">
                                                            <label className="form-label">CT turn ratio</label>

                                                            <Field type="number" min="0" max="1000" className="form-control" name='node3_ctval' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <h6>Node 4</h6>
                                                        <div className="col-md-12">
                                                            <label className="form-label">CT turn ratio</label>
                                                            <Field type="number" min="0" max="1000" className="form-control" name='node4_ctval' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-lg-12">
                                            <div className="card mb-3">
                                                <div className="card-body">

                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-md-12">
                                                            <label className="form-label">Avg Electricity bill &nbsp;</label>
                                                            <label className='form-label' style={{ "color": "red" }}>
                                                                <ErrorMessage name='avg_bill' />
                                                            </label>
                                                            <Field type="number" className="form-control" name='avg_bill' />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label className="form-label">Air-conditioning capacity &nbsp;</label>
                                                            <label className='form-label' style={{ "color": "red" }}>
                                                                <ErrorMessage name='avg_conditioning_capacity' />
                                                            </label>
                                                            <Field type="number" className="form-control" name='avg_conditioning_capacity' />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label className="form-label">Space size  &nbsp;</label>
                                                            <label className='form-label' style={{ "color": "red" }}>
                                                                <ErrorMessage name='space_size' />
                                                            </label>
                                                            <Field type="number" className="form-control" name='space_size' />
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label className="form-label">Air-conditioned area %   &nbsp;</label>
                                                            <label className='form-label' style={{ "color": "red" }}>
                                                                <ErrorMessage name='air_conditioned_area' />
                                                            </label>
                                                            <Field as="select" className="form-control" name='air_conditioned_area' >
                                                                <option value=""></option>
                                                                <option value="0">0-25</option>
                                                                <option value="0.4">26-50</option>
                                                                <option value="0.7">51-75</option>
                                                                <option value="1">76-100</option>
                                                            </Field>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label className="form-label">Geographical location  &nbsp;</label>
                                                            <label className='form-label' style={{ "color": "red" }}>
                                                                <ErrorMessage name="geographical_location" />
                                                            </label>
                                                            <Field as="select" className="form-control" name='geographical_location' >
                                                                <option value=""></option>
                                                                <option value="0.75">6</option>
                                                                <option value="0.80">7</option>
                                                                <option value="0.85">8</option>
                                                                <option value="0.9">9</option>
                                                                <option value="1">10</option>

                                                            </Field>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label className="form-label">Operating hours &nbsp;</label>
                                                            <label className="form-label" style={{ "color": "red" }}>
                                                                <ErrorMessage name='operating_hours' />
                                                            </label>
                                                            <Field type="number" max="24" min="8" className="form-control" name='operating_hours' />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Form>
                            </Formik>
                            : ""
                    }

                </div>
            </div>
        </>
    )
}






export default NewGatewayComp