import { Link } from "react-router-dom"

const List = ({ cust }) => {
    const fireBaseTime = new Date(
        cust.CreatedAt._seconds * 1000 + cust.CreatedAt._nanoseconds / 1000000,
    );
    const date = fireBaseTime.toDateString();

    return (
        <>
            <tr>
                <th scope="row">{cust.Email}</th>
                <td>@{cust.username}</td>
                {/* <td><img src={cust.ProfileURI ? cust.ProfileURI : avatar}
                    className="avatar lg rounded me-2"
                    alt="" /></td> */}
                {/* <td>{cust.Address}</td>  */}
                <td>{date}</td>

               
                    <td> <Link to={`/custinfo/${cust.OrganizationID}`} style={{ textDecoration: "none" }}>Details </Link></td>
               

            </tr>
        </>
    )
}

export default List