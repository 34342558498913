import AWS from "aws-sdk";
import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import axios from 'axios'
import Cookies from 'js-cookie'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import Loader from '../../components/loader'

const Report = () => {
    const [allgateway, setAllGateway] = useState([])
    const [isloading, setIsloading] = useState(true)

    const [reportGateway, setreportGateway] = useState()
    const [file, setFile] = useState(null)
    const [fileProgressBar, setFileProgressBar] = useState(0);
    const [monthyear, setMonthyear] = useState(null)


   

    useEffect(() => {
        const fetchgateway = async () => {
            const config = {
                headers: {
                    'Authorization': `${Cookies.get('Authorization')}`,
                }
            };
            const fetchedgateways = await axios.get('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/allgateway', config)
            setAllGateway(fetchedgateways.data)
            setIsloading(false)
        }
        setIsloading(true)
        fetchgateway()

    }, [])

    const SetReport = (event) => {
        setFile(event.target.files[0]);
    }


    const UploadReport = async (e) => {
        e.preventDefault();
        const [year, month] = monthyear.split('-');
        const formattedDate = year + month;
        const filename = formattedDate + '.pdf'

        const renamedFile = new File([file], filename);

        const S3_BUCKET = "soulreport";
        const REGION = "ap-south-1";

        AWS.config.update({
            accessKeyId: "AKIAQRZ7237UCEGYDM5O",
            secretAccessKey: "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",
        });

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });


        const params = {
            Bucket: S3_BUCKET,
            Key: `${reportGateway}/${filename}`,
            Body: renamedFile
        };

        let upload = s3
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                setFileProgressBar(parseInt((evt.loaded * 100) / evt.total));
            })
            .promise();

        await upload.then((err, data) => {
            console.log(err);
        });

        toast.success("Report successfully uploaded.", {
            icon: "🚀",
        });
       
    }


    return (
        <>
            <div id="ebazar-layout" className="theme-blush">
                <Sidebar />

                <div className="main px-lg-4 px-md-4">
                    <Header />
                    <div className="body d-flex py-3">
                        <div className="container-xxl">
                            <div className="row align-items-center">
                                <div className="border-0 mb-4">
                                    <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                                        <h3 className="fw-bold mb-0">Report</h3>
                                    </div>
                                </div>
                            </div>
                            {
                                isloading ? <Loader /> :
                                    <div className="row g-3 my-2">
                                        <div className="col-xl-12 col-lg-12">
                                            <ToastContainer position="top-center" />

                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <form id="my-form">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-md-12">
                                                                <label className="form-label">
                                                                    Gateway
                                                                </label>
                                                                <select name="gatewayID" id="gatewayID" className='form-select' onChange={(e) => setreportGateway(e.target.value)} autoFocus={true}>
                                                                    <option></option>

                                                                    {
                                                                        allgateway.map((data) => {
                                                                            return (
                                                                                <option value={data.gatewayID} key={data.gatewayID} >{data.gatewayID}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </select>


                                                            </div>
                                                            <div className="col-md-12">
                                                                <label className="form-label">
                                                                    Month
                                                                </label>
                                                                <input
                                                                    type="month"
                                                                    id="bdaymonth"
                                                                    name="bdaymonth"
                                                                    className='form-control'
                                                                    onChange={(e) => setMonthyear(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label className="form-label">
                                                                    Report
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name="report"
                                                                    onChange={SetReport}
                                                                    accept=".pdf, .txt"
                                                                    required
                                                                />

                                                                {file ? (
                                                                    <div
                                                                        className="progress"
                                                                        role="progressbar"
                                                                        aria-label="Animated striped example"
                                                                        aria-valuenow="25"
                                                                        aria-valuemin="0"
                                                                        aria-valuemax="100"
                                                                        style={{ height: "10px" }}
                                                                    >
                                                                        <div
                                                                            className="progress-bar progress-bar-striped progress-bar-animated"
                                                                            style={{ width: `${fileProgressBar}%` }}
                                                                        >
                                                                            {fileProgressBar}%
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            {
                                                                file && monthyear && reportGateway ?
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        type="button"
                                                                        onClick={UploadReport}
                                                                    >
                                                                        Upload
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        type="button"
                                                                        disabled
                                                                    >
                                                                        Upload
                                                                    </button>
                                                            }
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Report