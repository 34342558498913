import { CognitoUserPool } from 'amazon-cognito-identity-js';
import avatar from '../../assets/icon01.png'
import { IconContext } from "react-icons";
import { FaBars } from "react-icons/fa"
import { FiLogOut } from "react-icons/fi"
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs"
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


const Header = () => {

    let navigate = useNavigate();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const logout = () => {
        const poolData = {
            UserPoolId: 'ap-south-1_fVxvFfNRz',
            ClientId: '2kkhc6onihn7muj66j2ifj9fd2'
        };

        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();

        cognitoUser.signOut();
        Cookies.remove('Authorization')
        navigate('/')
    }

    const zoomfullscreen = () => {
        document.body.requestFullscreen();
        setIsFullscreen(true)
      
    }

    const hidefullscreen = () => {
        document.exitFullscreen();
        setIsFullscreen(false)
       
    }

    return (
        <>
            <div className="header" id='header'>
                <nav className="navbar py-4">
                    <div className="container-xxl">

                        <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
                            <div className="dropdown notifications">
                                <button className="nav-link dropdown-toggle pulse">
                                    {
                                        isFullscreen ?
                                            <IconContext.Provider value={{className: "global-class-name", size: '20px' }}>
                                                <div>
                                                    <BsFullscreenExit onClick={hidefullscreen} />
                                                </div>
                                            </IconContext.Provider> :
                                            <IconContext.Provider value={{  className: "global-class-name", size: '20px' }}>
                                                <div>
                                                    <BsArrowsFullscreen onClick={zoomfullscreen} />
                                                </div>
                                            </IconContext.Provider>
                                    }
                                </button>
                            </div>
                            <div className="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center zindex-popover">
                                <div className="u-info me-2">
                                    <p className="mb-0 text-end line-height-sm "><span className="font-weight-bold">{localStorage.getItem('admin')}</span></p>

                                </div>
                                <a className="nav-link dropdown-toggle pulse p-0" href="/dashboard" role="button"
                                    data-bs-toggle="dropdown" type='button' >
                                    <img className="avatar lg rounded-circle img-thumbnail"
                                        src={avatar} alt="profile" />
                                </a>
                                <div
                                    className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                                    <div className="card border-0 w280">
                                        <div className="list-group m-2 ">
                                            <button className="list-group-item list-group-item-action border-0 " onClick={logout}> <FiLogOut className=" fs-5 me-3" /> Signout</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                     


                        <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 ">

                        </div>



                    </div>
                </nav>
            </div>
        </>
    )
}

export default Header