import "./App.css"
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";


import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { UserContext } from "./helper/Context";
import { useRef, useState } from "react";

import Custinfo from "./pages/custinfo";
import GatewayInfo from "./pages/gatewayinfo";
import Home from "./pages/home";
import Signin from "./pages/signin";
import NewGateway from "./pages/newgateway";
import Gateway from "./pages/gateway";
import Mqtt from "./pages/mqtt";
import NotFound from "./pages/notfound";
import NewRegisters from "./pages/newregisters";
import NewRegisterInfo from "./pages/newregisterinfo";
import Bin from "./pages/bin/Bin";

import cardimage from './assets/notsupport.jpeg'
import Report from "./pages/report/Report";
import Invoice from "./pages/invoice";

const App = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);


  const [user, setUser] = useState({})

  return (
    <>
    {windowSize.current[0] >= 750 ?
      <UserContext.Provider value={{ user, setUser }}>
        <Router>
          <Routes>
            <Route name="signin" exact path="/" element={<Signin />} />
            <Route name="home" exact path="/dashboard" element={<Home/>} />
            <Route name="newregister" exact path="/newregister" element={<NewRegisters />} />
            <Route name="newregisterinfo" exact path="/newregister/:registerid" element={<NewRegisterInfo />} />

            <Route name="custinfo" exact path="/custinfo/:userid" element={<Custinfo />} />
            <Route name="gateway" exact path="/gateway" element={<Gateway />} />
            <Route name="gatewayinfo" exact path="/gatewayinfo/:gatewayid" element={<GatewayInfo />} />
            <Route name="newgateway" exact path="/newgateway" element={<NewGateway />} />
            <Route name="mqtt" exact path="/mqtt" element={<Mqtt />} />
            <Route name="ota" exact path="/ota" element={<Bin/>} />

            <Route name="report" exact path="/report" element={<Report/>} />

            <Route name="invoice" exact path="/invoice" element={<Invoice/>} />

            <Route name="*" exact path="*" element={<NotFound />} />

          </Routes>
        </Router>
      </UserContext.Provider>
      :
        <div className="row">
        <div className="col-sm-12 my-5">
          <div className="card" >
            <img className="card-img-top" src={cardimage} alt="" />
            <div className="card-body">
              <p className="card-text">Device not supported. please use a desktop.</p>
            </div>
          </div>
          </div>
        </div>}

    </>
  );
}

export default App;
