// import Cards from '../cards'
import Gateways from './gateways'


const GatewayComp = () => {
    return (
        <>
            <div className="body d-flex py-3">
                <div className="container-xxl">
                   {/* <Cards/> */}
                   <Gateways/>
                </div>
            </div>

        </>
    )
}

export default GatewayComp