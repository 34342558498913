import axios from "axios"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const NewRegisterDetails = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const cookie = Cookies.get('Authorization')

        if (!cookie) {
            navigate("/")
        }

    })

    const { registerid } = useParams()
    const [regiseruser, setRegisteruser] = useState({})

    useEffect(() => {
        const fetchregisteruser = async () => {
            const config = {
                headers: {
                    'Authorization': `${Cookies.get('Authorization')}`,
                }
            };
            const fetchuser = await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/registerinfo', {
                "RegistrationID": registerid
            }, config)

            setRegisteruser(fetchuser.data)
        }
        fetchregisteruser();

    }, [registerid])


    
    return (
        <>
            <ToastContainer />
            <div className="body d-flex py-3">
                <div className="container-xxl">
                    <div className="row align-items-center">
                        <div className="border-0 mb-4">
                            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                                <h3 className="fw-bold mb-0">Assign Gateway</h3>
                                <button type="submit" className="btn btn-primary btn-set-task w-sm-100 py-2 px-5 text-uppercase" form="my-form">Save</button>

                            </div>
                        </div>
                    </div>

                    <div className="row g-3 mb-xl-3">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                            <div className="card auth-detailblock">
                                <div className="card-body">
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Email</label>
                                            <span>{regiseruser.Email}</span>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Username:</label>
                                            <span>@{regiseruser.username}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Formik
                        initialValues={{
                            gatewayID: '',
                            Address: '',
                            name: '',

                            node1_ctval: '',
                            node2_ctval: '',
                            node3_ctval: '',
                            node4_ctval: ''
                        }}

                        validationSchema={Yup.object({
                            gatewayID: Yup.string()
                                .max(20, 'Must be 20 characters or less')
                                .min(5, 'Must be 5 characters or more')
                                .required('Required'),

                            name: Yup.string()
                                .max(25, 'Must be 20 characters or less')
                                .min(5, 'Must be 5 characters or more')
                                .required('Required'),
                                
                            Address: Yup.string()
                                .required('Required'),

                            node1_ctval: Yup.number()
                                .min(0,'Must be 0 or more')
                                .max(1000,'Must be 1000 or less'),

                            node2_ctval: Yup.number()
                                .min(0,'Must be 0 or more')
                                .max(1000,'Must be 1000 or less'),
                            
                            node3_ctval: Yup.number()
                                .min(0,'Must be 0 or more')
                                .max(1000,'Must be 1000 or less'),

                            node4_ctval: Yup.number()
                                .min(0,'Must be 0 or more')
                                .max(1000,'Must be 1000 or less'),   
                        })}

                        onSubmit={async (values) => {
                            try {

                                const params = {
                                    "OrganizationID": `${regiseruser.RegistrationID}`,
                                    "Email": `${regiseruser.Email}`,
                                    "username": `${regiseruser.username}`,
                                    "Address": values.Address,
                                    "gatewayID": values.gatewayID,
                                    "name": values.name,
                                    "node1": {
                                        "ctval": values?.node1_ctval || "",
                                    },
                                    "node2": {
                                        "ctval": values?.node2_ctval || ""
                                    },
                                    "node3": {
                                        "ctval": values?.node3_ctval || ""
                                    },
                                    "node4": {
                                        "ctval": values?.node4_ctval || ""
                                    }

                                }
                                const config = {
                                    headers: {
                                        'Authorization': `${Cookies.get('Authorization')}`,
                                    }
                                };
                                await axios.post('https://lzadbq0w31.execute-api.ap-south-1.amazonaws.com/development/shiftregistertocustomer', params, config)

                                toast("Successfully assigned gateway.", {
                                    position: toast.POSITION.TOP_CENTER
                                })
                                navigate('/newregister')

                            }
                            catch (error) {
                                toast.error(error, {
                                    position: toast.POSITION.TOP_CENTER
                                })
                            }
                        }}
                    >
                        <Form id="my-form">
                            <div className="row g-3 mb-xl-3">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card mb-3">
                                        <div className="card-body">

                                            <div className="row g-3 align-items-center">
                                                <div className="col-md-12">
                                                    <label className="form-label">Gateway ID &nbsp;</label>
                                                    <label className="form-label" style={{ "color": "red" }}>
                                                        <ErrorMessage name="gatewayID" />
                                                    </label>
                                                    <Field type="text" className="form-control" name='gatewayID' />
                                                </div>

                                                <div className="col-md-12">
                                                    <label className="form-label">Gateway Address &nbsp;</label>
                                                    <label className="form-label" style={{ "color": "red" }}>
                                                        <ErrorMessage name="Address" />
                                                    </label>
                                                    <Field type="text" className="form-control" name='Address' />
                                                </div>

                                                <div className="col-md-12">
                                                    <label className="form-label">Gateway Name &nbsp;</label>
                                                    <label className="form-label" style={{ "color": "red" }}>
                                                        <ErrorMessage name="name" />
                                                    </label>
                                                    <Field type="text" className="form-control" name='name' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row g-3">
                                                <h6>Node 1</h6>
                                                <div className="col-md-12">
                                                    <label className="form-label">CT turn ratio &nbsp;</label>
                                                    <label className="form-label" style={{ "color": "red" }}>
                                                        <ErrorMessage name="node1_ctval" />
                                                    </label>
                                                    <Field type="number" min="0" max="1000" className="form-control" name='node1_ctval' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row g-3">
                                                <h6>Node 2</h6>
                                                <div className="col-md-12">
                                                    <label className="form-label">CT turn ratio</label>
                                                    <label className="form-label" style={{ "color": "red" }}>
                                                        <ErrorMessage name="node2_ctval" />
                                                    </label>
                                                    <Field type="number" min="0" max="1000" className="form-control" name='node2_ctval' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row g-3">
                                                <h6>Node 3</h6>
                                                <div className="col-md-12">
                                                    <label className="form-label">CT turn ratio</label>
                                                    <label className="form-label" style={{ "color": "red" }}>
                                                        <ErrorMessage name="node3_ctval" />
                                                    </label>
                                                    <Field type="number" min="0" max="1000" className="form-control" name='node3_ctval' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row g-3">
                                                <h6>Node 4</h6>
                                                <div className="col-md-12">
                                                    <label className="form-label">CT turn ratio</label>
                                                    <label className="form-label" style={{ "color": "red" }}>
                                                        <ErrorMessage name="node4_ctval" />
                                                    </label>
                                                    <Field type="number" min="0" max="1000" className="form-control" name='node4_ctval' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default NewRegisterDetails